import * as authService from '@services/oauthService'
import appStore from '@stores/appStore'
import dashboard from '@stores/dashboard'
import { APP_ROUTES } from '@utils/routesConfig'
import cx from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { translate } from 'translator'
import Tooltip from '../../../components/shared/Tooltip'
import { MenuGroup, MenuItem } from '../MenuItem'
import './SidebarMenus.scss'
import localAccountManager from '@services/localAccountManager'

const MenuConfig = {
  CaseManager: props => {
    return appStore.edition.MLP || appStore.edition.MARKETING ? <MenuItem {...props} /> : null
  },
  Assessment: props => {
    return appStore.features.Assessment ? <MenuItem {...props} /> : null
  },
  MassTorts: props => {
    return appStore.features.MassTorts ? <MenuItem {...props} /> : null
  },
  OT: props => {
    return appStore.features.OT ? <MenuItem {...props} /> : null
  },
  TreatmentProvider: props => {
    return appStore.features.TreatmentProvider ? <MenuItem {...props} /> : null
  },
  FileReview: props => {
    return appStore.features.FileReview ? <MenuItem {...props} /> : null
  },
  Supplementary: props => {
    return appStore.features.Supplementary ? <MenuItem {...props} /> : null
  },
  MedicalRecord: props => {
    return appStore.features.MedicalRecord ? <MenuItem {...props} /> : null
  },
  MedNeg: props => {
    return appStore.features.MedNeg ? <MenuItem {...props} /> : null
  },
  NDIS: props => {
    return appStore.features.NDIS ? <MenuItem {...props} /> : null
  },
  InquiryCentre: props => {
    return appStore.features.InquiryCentre ? <MenuItem {...props} /> : null
  },
  MarketingCampaign: props => {
    return appStore.features.MarketingCampaign ? <MenuItem {...props} /> : null
  },
  Disbursement: props => {
    return appStore.features.Disbursement ? <MenuItem {...props} /> : null
  },
  TimeTracking: props => {
    return appStore.features.TimeTracking ? <MenuItem {...props} /> : null
  },
  LegalPracticeAPI: props => {
    return appStore.features.LegalPracticeAPI ? <MenuItem {...props} /> : null
  },
  MVA: props => {
    return appStore.features.MVA ? <MenuItem {...props} /> : null
  },
}

const HostTenantAdminMenu = observer(() => (
  <>
    {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
    <MenuItem path={APP_ROUTES.tenant.list} title="TENANTS" materialIcon />
    <MenuItem path="/view/db-migration" title="DB MIGRATION" materialIcon />
    <MenuItem path={APP_ROUTES.roles.list} title="ROLES" materialIcon />
    <MenuItem path={APP_ROUTES.editions.list} title="EDITIONS" materialIcon />
    <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
      <MenuItem path={APP_ROUTES.company.list} noImage title="Companies" />
      <MenuItem path={APP_ROUTES.caseManager.list} noImage title="Case Managers" />
      <MenuItem path={APP_ROUTES.user.list} noImage />
    </MenuGroup>

    <MenuGroup title="Usage & Invoicing" hidden name="subscription" materialIcon>
      <MenuItem path={APP_ROUTES.billingSettingList.list} noImage />
      <MenuItem path={APP_ROUTES.monthlyActiveAccounts.list} noImage />
      <MenuItem path={APP_ROUTES.tenantInvoice.list} noImage />
      <MenuItem path={'/view/outstanding-balance-report'} noImage />
    </MenuGroup>
    <MenuGroup title="Power BI Reports" materialIcon name={'powerbi'} hidden>
      <MenuItem path="/view/service-revenue-master-report" title="Service Revenue" noImage />
      <MenuItem path="/view/user-account-analysis-report" title="User Account" noImage />
      <MenuItem path="/view/funding-analysis-report" title="Lender Funding Analysis" noImage />
    </MenuGroup>
    <MenuGroup title="MASTER DATA" hidden name="master-data" materialIcon>
      <MenuItem path={APP_ROUTES.centre.list} noImage />
      <MenuItem path={APP_ROUTES.countries.list} noImage />
      <MenuItem path={APP_ROUTES.cities.list} noImage />
      <MenuItem path={APP_ROUTES.attachmentTypes.list} noImage />
      <MenuItem path={APP_ROUTES.languages.list} noImage />
    </MenuGroup>
    <MenuGroup title="App Settings" hidden name="SettingOutlined" materialIcon>
      <MenuItem title="Value List" path="/view/lookup-config" noImage />
      <MenuItem path={APP_ROUTES.mailTemplate.list} noImage />
      <MenuItem path={APP_ROUTES.systemConfig.list} title="System Configuration" noImage />
      <MenuItem path="/view/holidays" noImage />
    </MenuGroup>
    <NotificationMenuGroup />

    <MenuItem path="/hangfire" materialIcon redirect={process.env.VITE_API_URL + '/hangfire'} />
  </>
))

const HostTenantAdminStaffMenu = observer(() => (
  <>
    <MenuItem path={APP_ROUTES.tenant.list} title="TENANTS" materialIcon />
    <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
      <MenuItem path={APP_ROUTES.company.list} noImage title="Companies" />
      <MenuItem path={APP_ROUTES.caseManager.list} noImage title="Case Managers" />
      <MenuItem path={APP_ROUTES.user.list} noImage />
    </MenuGroup>
    <MenuItem path={APP_ROUTES.marketingCampaign.list} title="Marketing Campaigns" name="SoundOutlined" materialIcon />
    <MenuGroup title="Usage & Invoicing" hidden name="subscription" materialIcon>
      <MenuItem path={APP_ROUTES.monthlyActiveAccounts.list} noImage />
      <MenuItem path={APP_ROUTES.tenantInvoice.list} noImage />
      <MenuItem path={APP_ROUTES.billingSettingList.list} noImage />
    </MenuGroup>
    <MenuGroup title="Power BI Reports" materialIcon name={'powerbi'} hidden>
      <MenuItem path="/view/service-revenue-master-report" noImage />
      <MenuItem path="/view/user-account-analysis-report" noImage />
      <MenuItem path="/view/funding-analysis-report" title="Lender Funding Analysis" noImage />
    </MenuGroup>
    <MenuGroup title="App Settings" hidden name="SettingOutlined" materialIcon>
      <MenuItem title="Value List" path="/view/lookup-config" noImage />
      <MenuItem path={APP_ROUTES.mailTemplate.list} noImage />
      <MenuItem path="/view/holidays" noImage />
    </MenuGroup>
    <NotificationMenuGroup />
  </>
))

const HostTenantStaffMenu = observer(() => (
  <>
    <MenuItem path={APP_ROUTES.tenant.list} title="TENANTS" materialIcon />
    <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
      <MenuItem path={APP_ROUTES.company.list} noImage title="Companies" />
      <MenuItem path={APP_ROUTES.caseManager.list} noImage title="Case Managers" />
      <MenuItem path={APP_ROUTES.user.list} noImage />
    </MenuGroup>
    {/* <MenuItem path={APP_ROUTES.marketingCampaign.list} title="Marketing Campaigns" name="SoundOutlined" materialIcon /> */}
    <MenuGroup title="Usage & Invoicing" hidden name="subscription" materialIcon>
      <MenuItem path={APP_ROUTES.monthlyActiveAccounts.list} noImage />
    </MenuGroup>
    <MenuGroup title="Power BI Reports" materialIcon name={'powerbi'} hidden>
      <MenuItem path="/view/user-account-analysis-report" title="User Account" noImage />
    </MenuGroup>
  </>
))

const DefaultMenu = observer(() => {
  const functionalCapacityAssessmentsClick = e => {
    e.preventDefault()
    return false
  }

  return (
    <>
      {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
      <MenuItem path="/view/dashboard" name="FundProjectionScreenOutlined" materialIcon />
      <MenuItem path="/view/calendar" name="Calendar" materialIcon />
      <MenuGroup title="Search Options" hidden name="search" materialIcon>
        <MenuItem path="/view/universal-search" noImage />
        <MenuItem path="/view/smart-search" name="search" title="Appointment Session Search" noImage />
      </MenuGroup>
      <MenuGroup title="My Cases" name="service-center" materialIcon>
        <MenuConfig.MassTorts path={APP_ROUTES.massTorts.list} noImage />
        <MenuConfig.OT path={APP_ROUTES.ot.list} noImage />
        <MenuConfig.Assessment path={APP_ROUTES.assessment.list} noImage />
        <MenuConfig.FileReview path={APP_ROUTES.fileReview.list} noImage />
        <MenuConfig.Supplementary path={APP_ROUTES.supplementary.list} noImage />
        <MenuConfig.MedicalRecord
          path={APP_ROUTES.medicalRecord.list}
          title="Medical Records Retrievals (MR)"
          noImage
        />
        <MenuConfig.NDIS path={APP_ROUTES.ndis.list} title="NDIS (ND)" noImage />
        <Tooltip appear="Contact us for more information regarding this service.">
          <div>
            <MenuItem
              path="/FunctionalCapacityAssessments"
              onClick={functionalCapacityAssessmentsClick}
              title="Functional Capacity Assessments"
              noImage
            />
          </div>
        </Tooltip>
      </MenuGroup>
    </>
  )
})

const SpecialistMenu = observer(() => (
  <React.Fragment>
    {/* <MenuItem path="/view/login-account" name="UserOutlined" title={'My Account'} materialIcon /> */}
    <MenuItem path="/view/dashboard" name="FundProjectionScreenOutlined" title="Dashboard" materialIcon />
    <MenuItem path="/view/universal-search" title="Find My Cases" materialIcon />
    <MenuItem path="/view/calendar" name="Calendar" title="My Clinic Calendar" materialIcon />
    <MenuGroup title="My Cases" name="service-center" materialIcon>
      <MenuConfig.MassTorts path={APP_ROUTES.massTorts.list} noImage />
      <MenuConfig.OT path={APP_ROUTES.ot.list} noImage />
      <MenuConfig.Assessment path={APP_ROUTES.assessment.list} noImage />
      <MenuConfig.FileReview path={APP_ROUTES.fileReview.list} noImage />
      <MenuConfig.Supplementary path={APP_ROUTES.supplementary.list} noImage />
    </MenuGroup>
    <MenuItem path="/view/send-feedback" title="SEND FEEDBACK" materialIcon />
  </React.Fragment>
))

const NotificationMenuGroup = observer(() => (
  <MenuGroup title="Notifications" hidden name="BellOutlined" materialIcon>
    <MenuItem path="/view/emails-tracking" noImage />
    <MenuItem path="/view/emails-sending-report" noImage />
    <MenuItem path="/view/system-error" noImage />
    <MenuItem path="/view/system-update" noImage />
  </MenuGroup>
))
const AdminMenu = observer(() => {
  return (
    <>
      {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
      <MenuItem path="/view/dashboard" title="Dashboard" name="FundProjectionScreenOutlined" materialIcon />
      <MenuGroup title="Search Options" hidden name="SearchOutlined" materialIcon>
        <MenuItem path="/view/smart-search" noImage />
        <MenuItem path="/view/universal-search" noImage />
        <MenuItem path="/view/action-required-search" noImage />
      </MenuGroup>
      <MenuItem path="/view/calendar" name="Calendar" materialIcon />
      <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
        <MenuItem path={APP_ROUTES.client.list} noImage title={translate('Clients')} />
        <MenuItem path={APP_ROUTES.company.list} noImage title={translate('Companies')} />
        <MenuConfig.CaseManager path={APP_ROUTES.caseManager.list} noImage />
        <MenuItem path={APP_ROUTES.contractor.list} noImage />
        {/* <MenuItem path={APP_ROUTES.serviceProvider.list} noImage /> */}
        <MenuItem path={APP_ROUTES.specialist.list} noImage />
        <MenuItem path={APP_ROUTES.centre.list} noImage />
        {appStore.isAdminStaff && <MenuItem path={APP_ROUTES.staff.list} noImage />}
        {appStore.isAdminStaff && <MenuItem path={APP_ROUTES.userGroup.list} noImage />}
        {appStore.isAdminStaff && <MenuItem path={APP_ROUTES.user.list} noImage />}
      </MenuGroup>
      {appStore.features.CaseManagement && (
        <MenuGroup title="Case Management" name="FolderOpenOutlined" materialIcon>
          <MenuConfig.MassTorts path={APP_ROUTES.massTorts.list} noImage />
          <MenuConfig.NDIS path={APP_ROUTES.ndis.list} noImage />
          <MenuConfig.MVA path={APP_ROUTES.mvaClaim.list} noImage />
          <MenuConfig.TimeTracking path={APP_ROUTES.timeTracking.list} noImage />
        </MenuGroup>
      )}
      <MenuGroup title="Reporting Services" name="AppstoreOutlined" materialIcon>
        <MenuConfig.Assessment path={APP_ROUTES.assessment.list} noImage />
        <MenuConfig.OT path={APP_ROUTES.ot.list} noImage />
        <MenuConfig.FileReview path={APP_ROUTES.fileReview.list} noImage />
        <MenuConfig.Supplementary path={APP_ROUTES.supplementary.list} noImage />
        <MenuConfig.MedicalRecord path={APP_ROUTES.medicalRecord.list} noImage />
        <MenuConfig.MedNeg path={APP_ROUTES.medNeg.list} noImage />
        <MenuConfig.MedNeg path={APP_ROUTES.co.list} noImage />
        <MenuItem path="/view/video-meeting" noImage />
      </MenuGroup>

      {appStore.features.Disbursement && (
        <MenuGroup title="Facility Disbursement" name="FacilityDisbursement" materialIcon>
          {/* {appStore.edition.FUNDING_PROVIDER && (
            <MenuItem path={APP_ROUTES.fundingRequest.list} title="Facility Requests" noImage />
          )} */}
          {appStore.edition.FUNDING_PROVIDER && <MenuItem path={APP_ROUTES.fundingAgreement.list} noImage />}
          {appStore.hasFeature('Application:FundingTransaction') && (
            <MenuItem path={APP_ROUTES.fundingTransaction.list} noImage />
          )}
          {appStore.hasFeature('Application:DisbursementFunding') && (
            <>
              <MenuItem path={APP_ROUTES.disbursementFunding.list} noImage />
              {appStore.hasFeature('Application:TransactionInvoice') && (
                <MenuItem path={APP_ROUTES.transactionInvoice.list} noImage />
              )}
            </>
          )}
        </MenuGroup>
      )}

      <MenuGroup title="Accounting & Remittance" hidden name="Accounting" materialIcon>
        <MenuItem path={APP_ROUTES.invoice.list} noImage />
        <MenuItem path="/view/remittance-statements" noImage />
      </MenuGroup>
      {appStore.hasFeature('Application:PowerBIReport') && (
        <MenuGroup title="Power BI Reports" materialIcon name={'powerbi'} hidden>
          {appStore.currentTenant.name === 'MAG' && <MenuItem path="/view/billing-kpi-report" noImage />}
          {appStore.currentTenant.name === 'MAG' && <MenuItem path="/view/staff-kpi-report" noImage />}
          {appStore.currentTenant.name === 'MAG' && <MenuItem path="/view/service-revenue-forecast-report" noImage />}
          <MenuItem path="/view/correspondence-analysis-report" noImage />
          <MenuItem path="/view/relationship-activities" noImage />
          <MenuItem path="/view/service-analysis-report" noImage />
          <MenuItem path="/view/service-revenue-analysis-report" noImage />
          <MenuItem path="/view/user-account-analysis-report" noImage />
          <MenuItem path="/view/specialist-recruitment-report" noImage />
          <MenuItem path="/view/client-cm-recruitment" noImage />
        </MenuGroup>
      )}
      <MenuConfig.MarketingCampaign path={APP_ROUTES.marketingCampaign.list} materialIcon name="SoundOutlined" />
      {appStore.features.MagExtraResources && (
        <MenuGroup title="MAG Extra Resources" name="Resources" materialIcon>
          <MenuItem
            path="/files/MAGDirectory"
            title="Specialist Panel"
            noImage
            onClick={() => {
              const filePath = `${process.env.VITE_API_URL}/files/${appStore.currentTenant?.extraProperties.DirectoryFile}`
              window.open(filePath, '_blank')
            }}
          />
          <MenuItem
            path="https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos"
            title="Seminars"
            noImage
            onClick={() => {
              window.open('https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos', '_blank')
            }}
          />
          <MenuItem
            path="https://medicolegalassessmentsgroup-my.sharepoint.com/:f:/g/personal/1drive_medicolegalassessmentsgroup_com_au/EtgYx-CNiI9LrJ5MgqcYHMwBUpdFETVl_7HCm6waVEf3DA?e=txUpO7"
            title="Education Channel"
            noImage
            onClick={() => {
              window.open(
                'https://medicolegalassessmentsgroup-my.sharepoint.com/:f:/g/personal/1drive_medicolegalassessmentsgroup_com_au/EtgYx-CNiI9LrJ5MgqcYHMwBUpdFETVl_7HCm6waVEf3DA?e=txUpO7',
                '_blank',
              )
            }}
          />
        </MenuGroup>
      )}
      {appStore.hasFeature('Application:Subscription') && appStore.isAdminStaff && (
        <MenuItem path={APP_ROUTES.billingSetting.detail} materialIcon name="AppstoreAddOutlined" />
      )}
      {appStore.features.LegalPracticeAPI && (
        <MenuGroup title="PMS Integration" hidden name="FileSyncOutlined" materialIcon>
          <MenuConfig.LegalPracticeAPI path={'/view/law-practice-softwares'} noImage />
        </MenuGroup>
      )}
      <MenuItem path="/view/chatGPT" materialIcon name="ChatGpt" />
      <MenuGroup title="App Settings" hidden name="SettingOutlined" materialIcon>
        {appStore.isAdminOrStaff && <MenuItem path="/view/lookup-config" noImage />}
        {appStore.isAdminOrStaff && (appStore.features.NDIS || appStore.features.OT) && (
          <MenuItem title="Time Budget Break Down" path="/view/time-budget-breakdown" noImage />
        )}
        <MenuItem path="/view/holidays" noImage />
        <MenuItem path={APP_ROUTES.serviceItem.list} noImage />
        {appStore.features.NDIS && <MenuItem path={APP_ROUTES.ndisServiceItem.list} noImage />}
        {appStore.isAdminOrStaff && <MenuItem path="/view/account-code" noImage />}
        <MenuItem path={APP_ROUTES.talkingPoint.list} noImage />
        <MenuItem path={APP_ROUTES.talkingPointCategory.list} noImage />
        <MenuItem path={APP_ROUTES.mailTemplate.list} noImage />
        {appStore.features.AdHocTemplate && <MenuItem path="/view/ad-hoc" noImage />}
        <MenuItem path={APP_ROUTES.saleTarget.list} title="Sale Targets" noImage />
        {appStore.hasFeature('Application:LMQ') && <MenuItem path={APP_ROUTES.checkList.list} noImage />}
        {appStore.isAdminStaff && <MenuItem path={APP_ROUTES.systemConfig.list} title="System Configuration" noImage />}

        {(appStore.isAdminStaff || appStore.isTenantAdmin || appStore.isStaff) && (
          <a href={process.env.VITE_API_URL} target="_blank" className="menu-item" rel="noreferrer">
            Tenant Admin Page
          </a>
        )}
      </MenuGroup>
      <NotificationMenuGroup />
    </>
  )
})

const ContractorMenu = observer(() => (
  <>
    {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
    <MenuItem path="/view/contractor-detail" title="MY DETAILS" materialIcon />
    <MenuItem path="/view/dashboard" name="FundProjectionScreenOutlined" materialIcon />
    <MenuGroup title="Search Options" hidden name="SearchOutlined" materialIcon>
      <MenuItem path="/view/universal-search" noImage />
    </MenuGroup>
    <MenuGroup title="My Cases" name="service-center" materialIcon>
      <MenuConfig.Assessment path={APP_ROUTES.assessment.list} noImage />
      <MenuConfig.FileReview path={APP_ROUTES.fileReview.list} noImage />
      <MenuConfig.Supplementary path={APP_ROUTES.supplementary.list} noImage />
    </MenuGroup>
  </>
))

const CaseManagerMenu = observer(() => (
  <>
    {/* <MenuItem path="/view/login-account" name="UserOutlined" title={'My Account'} materialIcon /> */}
    {/* <MenuItem path="/view/cm-profile" title="CaseManager Profile" materialIcon /> */}
    {/* <MenuItem path="/view/my-account" title="My Account" materialIcon /> */}
    <MenuItem path="/view/dashboard" name="FundProjectionScreenOutlined" materialIcon />
    <MenuItem path="/view/calendar" name="Calendar" materialIcon />
    <MenuGroup title="Search Options" hidden name="search" materialIcon>
      <MenuItem path="/view/universal-search" noImage />
      <MenuItem path="/view/smart-search" name="search" noImage />
    </MenuGroup>
    <MenuGroup title="My Cases" name="service-center" materialIcon>
      <MenuConfig.MassTorts path={APP_ROUTES.massTorts.list} noImage />
      <MenuConfig.OT path={APP_ROUTES.ot.list} noImage />
      <MenuConfig.Assessment path={APP_ROUTES.assessment.list} noImage />
      <MenuConfig.FileReview path={APP_ROUTES.fileReview.list} noImage />
      <MenuConfig.Supplementary path={APP_ROUTES.supplementary.list} noImage />
      <MenuConfig.MedicalRecord
        path={APP_ROUTES.medicalRecord.list}
        title={'Medical Records Retrievals (MR)'}
        noImage
      />
      <MenuConfig.MedNeg path={APP_ROUTES.medNeg.list} noImage />
      <MenuConfig.MedNeg path={APP_ROUTES.co.list} noImage />
      <MenuConfig.NDIS path={APP_ROUTES.ndis.list} noImage />
    </MenuGroup>
    {appStore.features.Disbursement && (
      <MenuGroup title="Facility Disbursement" name="FacilityDisbursement" materialIcon>
        {/* {appStore.edition.FUNDING_PROVIDER && (
            <MenuItem path={APP_ROUTES.fundingRequest.list} title="Facility Requests" noImage />
          )} */}
        {appStore.edition.FUNDING_PROVIDER && <MenuItem path={APP_ROUTES.fundingAgreement.list} noImage />}
        {appStore.hasFeature('Application:FundingTransaction') && (
          <MenuItem path={APP_ROUTES.fundingTransaction.list} noImage />
        )}
        {appStore.hasFeature('Application:DisbursementFunding') && (
          <>
            <MenuItem path={APP_ROUTES.disbursementFunding.list} noImage />
            {appStore.hasFeature('Application:TransactionInvoice') && (
              <MenuItem path={APP_ROUTES.transactionInvoice.list} noImage />
            )}
          </>
        )}
      </MenuGroup>
    )}
    {appStore.features.MagExtraResources && (
      <MenuGroup title="MAG Extra Resources" name="Resources" materialIcon>
        <MenuItem
          path="/files/MAGDirectory"
          title="Specialist Panel"
          noImage
          onClick={() => {
            const filePath = `${process.env.VITE_API_URL}/files/${appStore.currentTenant?.extraProperties.DirectoryFile}`
            window.open(filePath, '_blank')
          }}
        />
        <MenuItem
          path="https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos"
          title="Seminars"
          noImage
          onClick={() => {
            window.open('https://www.youtube.com/channel/UC58K6nQi0LQ4HHNsNx2Xryw/videos', '_blank')
          }}
        />
        <MenuItem
          path="https://medicolegalassessmentsgroup-my.sharepoint.com/:f:/g/personal/1drive_medicolegalassessmentsgroup_com_au/EtgYx-CNiI9LrJ5MgqcYHMwBUpdFETVl_7HCm6waVEf3DA?e=txUpO7"
          title="Education Channel"
          noImage
          onClick={() => {
            window.open(
              'https://medicolegalassessmentsgroup-my.sharepoint.com/:f:/g/personal/1drive_medicolegalassessmentsgroup_com_au/EtgYx-CNiI9LrJ5MgqcYHMwBUpdFETVl_7HCm6waVEf3DA?e=txUpO7',
              '_blank',
            )
          }}
        />
      </MenuGroup>
    )}
  </>
))

/**
 * Insurer is Contractor of Insurer Company (Type = Insurer). This role visible on LawConnect only.
 */
const InsurerLawConnectMenu = observer(() => (
  <>
    {/* <MenuItem path="/view/login-account" name="UserOutlined" title={'My Account'} materialIcon /> */}
    {appStore.hasFeature('Application:Disbursement') && (
      <MenuGroup title="Facility Disbursement" name="FacilityDisbursement" materialIcon>
        {appStore.hasFeature('Application:FundingTransaction') && (
          <MenuItem path={APP_ROUTES.fundingTransaction.list} title="Loan Fundings" noImage />
        )}
        {appStore.hasFeature('Application:DisbursementFunding') && (
          <MenuItem path={APP_ROUTES.disbursementFunding.list} title="Funding Transactions" noImage />
        )}
      </MenuGroup>
    )}
  </>
))

/**
 * This role for ADMIN, ADMIN_STAFF of FUNDING_PROVIDER
 */
const FundingProviderAdminStaffMenuItems = observer(() => {
  return (
    <>
      {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
      <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
        <MenuItem path={APP_ROUTES.client.list} noImage title="Clients" />
        <MenuItem path={APP_ROUTES.company.list} noImage title={translate('Companies')} />
        <MenuItem path={APP_ROUTES.caseManager.list} noImage />
        <MenuItem path={APP_ROUTES.serviceProvider.list} noImage />
        <MenuItem path={APP_ROUTES.staff.list} noImage />
        <MenuItem path={APP_ROUTES.user.list} noImage />
      </MenuGroup>
      {appStore.hasFeature('Application:Disbursement') && (
        <MenuGroup title="Facility Disbursement" name="FacilityDisbursement" materialIcon>
          <MenuItem path={APP_ROUTES.fundingAgreement.list} title="Facility Agreements" noImage />
          {appStore.hasFeature('Application:FundingTransaction') && (
            <MenuItem path={APP_ROUTES.fundingTransaction.list} title="Loan Fundings" noImage />
          )}
          {appStore.hasFeature('Application:DisbursementFunding') && (
            <>
              <MenuItem path={APP_ROUTES.disbursementFunding.list} title="Funding Transactions" noImage />
              {appStore.hasFeature('Application:TransactionInvoice') && (
                <MenuItem path={APP_ROUTES.transactionInvoice.list} title="Funding Invoices" noImage />
              )}
            </>
          )}
        </MenuGroup>
      )}
      {appStore.hasFeature('Application:PowerBIReport') && (
        <MenuGroup title="Power BI Reports" materialIcon name={'powerbi'} hidden>
          {/* <MenuItem path="/view/funding-analysis-report" title="Funding Analysis" noImage /> */}
          <MenuItem path="/view/disbursement-funding-report" title="Funding Transactions" noImage />
        </MenuGroup>
      )}
      <MenuGroup title="App Settings" hidden name="SettingOutlined" materialIcon>
        {appStore.isAdminOrStaff && <MenuItem title="Value List" path="/view/lookup-config" noImage />}
        <MenuItem path={APP_ROUTES.serviceItem.list} noImage />
        <MenuItem path={APP_ROUTES.mailTemplate.list} noImage />
        {appStore.hasFeature('Application:LMQ') && <MenuItem path={APP_ROUTES.checkList.list} noImage />}
        {appStore.isAdminStaff && <MenuItem path="/view/system-config" title="System Configuration" noImage />}
        {(appStore.isAdminStaff || appStore.isTenantAdmin || appStore.isStaff) && (
          <a href={process.env.VITE_API_URL} target="_blank" className="menu-item" rel="noreferrer">
            Tenant Admin Page
          </a>
        )}
      </MenuGroup>
      <NotificationMenuGroup />
    </>
  )
})

const FundingProviderCaseManagerMenuItems = observer(() => {
  return (
    <>
      {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
      <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
        <MenuItem path={APP_ROUTES.client.list} noImage title={translate('Clients')} />
        <MenuItem path={APP_ROUTES.serviceProvider.list} noImage />
      </MenuGroup>
      {appStore.hasFeature('Application:Disbursement') && (
        <MenuGroup title="Facility Disbursement" name="FacilityDisbursement" materialIcon>
          <MenuItem path={APP_ROUTES.fundingAgreement.list} title="Facility Agreements" noImage />
          {appStore.hasFeature('Application:FundingTransaction') && (
            <MenuItem path={APP_ROUTES.fundingTransaction.list} title="Loan Fundings" noImage />
          )}
          {appStore.hasFeature('Application:DisbursementFunding') && (
            <>
              <MenuItem path={APP_ROUTES.disbursementFunding.list} title="Funding Transactions" noImage />
              {appStore.hasFeature('Application:TransactionInvoice') && (
                <MenuItem path={APP_ROUTES.transactionInvoice.list} title="Funding Invoices" noImage />
              )}
            </>
          )}
        </MenuGroup>
      )}
    </>
  )
})

const LMQProviderMenuItems = observer(() => {
  return (
    <>
      {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
      <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
        <MenuItem path={APP_ROUTES.company.list} noImage title={translate('Companies')} />
        <MenuItem path={APP_ROUTES.staff.list} noImage />
        <MenuItem path={APP_ROUTES.user.list} noImage />
      </MenuGroup>
      {appStore.features.CaseManagement && (
        <MenuGroup title="Case Management" name="FolderOpenOutlined" materialIcon>
          <MenuConfig.MassTorts path={APP_ROUTES.massTorts.list} title="Mass Torts (MT)" noImage />
          <MenuConfig.OT path={APP_ROUTES.ot.list} noImage />
        </MenuGroup>
      )}

      {appStore.hasFeature('Application:Disbursement') && (
        <MenuGroup title="Facility Disbursement" name="FacilityDisbursement" materialIcon>
          {/* <MenuItem path={APP_ROUTES.fundingRequest.list} title="Facility Requests" noImage /> */}
          <MenuItem path={APP_ROUTES.fundingAgreement.list} title="Facility Agreements" noImage />
          {appStore.hasFeature('Application:FundingTransaction') && (
            <MenuItem path={APP_ROUTES.fundingTransaction.list} title="Loan Fundings" noImage />
          )}
          {appStore.hasFeature('Application:DisbursementFunding') && (
            <MenuItem path={APP_ROUTES.disbursementFunding.list} title="Funding Transactions" noImage />
          )}
        </MenuGroup>
      )}

      <MenuGroup title="App Settings" hidden name="SettingOutlined" materialIcon>
        {appStore.isAdminOrStaff && <MenuItem title="Value List" path="/view/lookup-config" noImage />}
        <MenuItem path={APP_ROUTES.mailTemplate.list} noImage />
        {appStore.hasFeature('Application:LMQ') && <MenuItem path={APP_ROUTES.checkList.list} noImage />}
        {appStore.isAdminStaff && (
          <React.Fragment>
            <MenuItem path="/view/system-config" title="System Configuration" noImage />
          </React.Fragment>
        )}
      </MenuGroup>
      <NotificationMenuGroup />
    </>
  )
})

const MVABroker = observer(() => {
  return (
    <>
      {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
      <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
        <MenuItem path={APP_ROUTES.client.list} noImage title="Clients" />
      </MenuGroup>
      {appStore.features.CaseManagement && (
        <MenuGroup title="Case Management" name="FolderOpenOutlined" materialIcon>
          <MenuConfig.MVA path={APP_ROUTES.mvaClaim.list} title="MVA (MV)" noImage />
        </MenuGroup>
      )}
    </>
  )
})

const MARKETING_MENU = observer(() => {
  return (
    <>
      {/* <MenuItem path="/view/login-account" name="UserOutlined" title={appStore.currentUser.fullName} materialIcon /> */}
      <MenuItem path="/view/dashboard" name="FundProjectionScreenOutlined" materialIcon />
      <MenuGroup title="User Accounts" hidden name="TeamOutlined" materialIcon>
        <MenuItem path={APP_ROUTES.company.list} noImage />
        <MenuConfig.CaseManager path={APP_ROUTES.caseManager.list} noImage />
        {/* {appStore.isAdminStaff && <MenuItem path={APP_ROUTES.staff.list} noImage />} */}
        {appStore.isAdminStaff && <MenuItem path={APP_ROUTES.user.list} noImage />}
      </MenuGroup>
      <MenuGroup title="Reporting Services" name="AppstoreOutlined" materialIcon>
        <MenuConfig.MarketingCampaign path={APP_ROUTES.marketingCampaign.list} noImage />
      </MenuGroup>

      {appStore.hasFeature('Application:Subscription') && appStore.isAdminStaff && (
        <MenuItem
          path={APP_ROUTES.billingSetting.detail}
          title="Subscription"
          materialIcon
          name="AppstoreAddOutlined"
        />
      )}
      <MenuGroup title="App Settings" hidden name="SettingOutlined" materialIcon>
        {appStore.isAdminOrStaff && <MenuItem title="Value List" path="/view/lookup-config" noImage />}
        <MenuItem path={APP_ROUTES.mailTemplate.list} noImage />
        {appStore.isAdminStaff && <MenuItem path={APP_ROUTES.systemConfig.list} title="System Configuration" noImage />}

        {(appStore.isAdminStaff || appStore.isTenantAdmin) && (
          <a href={process.env.VITE_API_URL} target="_blank" className="menu-item" rel="noreferrer">
            Tenant Admin Page
          </a>
        )}
      </MenuGroup>
      <NotificationMenuGroup />
    </>
  )
})

const MenuItemsForMLPandLC = observer(() => {
  if (appStore.edition.MARKETING) {
    return <MARKETING_MENU />
  }

  if (appStore.isHostAdmin()) {
    return <HostTenantAdminMenu />
  }

  if (appStore.isHostAdminStaff()) {
    return <HostTenantAdminStaffMenu />
  }

  if (appStore.isHostStaff()) {
    return <HostTenantStaffMenu />
  }

  if (appStore.isSpecialist) {
    return <SpecialistMenu />
  }

  if (appStore.isAdminOrStaff) {
    return <AdminMenu />
  }

  if (appStore.isBrokerContractor) {
    return <MVABroker />
  }

  if (appStore.isInsurerContractor) {
    return <InsurerLawConnectMenu />
  }

  if (appStore.isContractor) {
    return <ContractorMenu />
  }

  if (appStore.isCaseManager) {
    return <CaseManagerMenu />
  }

  return <DefaultMenu />
})

const MenuForEdition = observer(() => {
  if (appStore.edition.FUNDING_PROVIDER) {
    if (appStore.isAdminOrStaff) {
      return <FundingProviderAdminStaffMenuItems />
    }

    if (appStore.isCaseManager) {
      return <FundingProviderCaseManagerMenuItems />
    }
  } else if (appStore.edition.LMQ_PROVIDER) {
    return <LMQProviderMenuItems />
  } else {
    return <MenuItemsForMLPandLC />
  }
})

const SidebarMenus = observer(() => {
  const logout = async event => {
    event.preventDefault()
    localAccountManager.removeCurrentAccount()
    await authService.logout()
  }

  const isDark = appStore.theme === 'dark'

  const themeSetting = () => {
    appStore.showThemeSetting = true
  }

  const { collapsed } = dashboard.sidebar
  return (
    <div className={cx([collapsed ? 'sm:p-2' : 'overflow-y-auto', 'pb-20 SidebarMenus'])}>
      <MenuForEdition />
      <MenuItem
        path="/view/theme-settings"
        title="Theme Settings"
        materialIcon
        name={`${isDark ? 'LightMode' : 'DarkMode'}`}
        onClick={themeSetting}
      />
      <MenuItem path="/view/logout" title="Logout" materialIcon name="LogoutOutlined" onClick={logout} />
      {appStore.isImpersonated && <MenuItem path="/account/RevertImpersonation" title="REVOKE" materialIcon />}
    </div>
  )
})

export default SidebarMenus
