import { ApiRequest } from '../baseService'

const BaseRoute = 'app/leap-app'

const GetMatterDetail = (id, tenantId) => {
  return ApiRequest.get(`${BaseRoute}/matters/${id}?tenantId=${tenantId ?? ''}`)
}

const GetMatters = () => {
  return ApiRequest.post(`${BaseRoute}/matters`, {
    method: 'Get',
  })
}

const GetPersons = filter => {
  return ApiRequest.post(`${BaseRoute}/persons`, {
    method: 'Get',
    data: filter?.query,
  })
}

const GetMatterAccount = matterId => {
  return ApiRequest.get(`${BaseRoute}/mattersAccount/` + matterId)
}

const API = {
  GetMatterDetail,
  GetMatters,
  GetMatterAccount,
  GetPersons,
}
export default API
